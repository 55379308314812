button.mat-mdc-unelevated-button.mat-mdc-button-base {
  height: 50px;
  color: #ffffff;
  border: 0px;
  font-size: 16px;
  width: 100%;
  cursor: pointer;
}

button[mat-button] {
  height: 50px !important;
}

button[mat-stroked-button] {
  height: 50px !important;
  font-size:16px;
}

button[mat-mini-fab] {
  box-shadow: none;
  height: 48px;
  width: 48px;
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dialog-header.icon-centering {
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding-top: 24px;
}

.dialog-header.icon-centering .icon {
  font-size: 32px;
  height: auto;
  width: auto;
  margin-bottom: 6px;
}

mat-dialog-content.mail-template-preview {
  height: calc(100% - 77px);
  overflow: hidden;
  max-height: unset;
}

.centering {
  text-align: center;
}

mat-dialog-actions {
  margin-bottom: 10px !important;
}

mat-dialog-actions > button {
  flex-basis: 40%;
}

.mdc-text-field--outlined .mdc-notched-outline__leading ,
.mdc-text-field--outlined .mdc-notched-outline__notch,
.mdc-text-field--outlined .mdc-notched-outline__trailing{
  border: none;
}

mat-form-field.mat-form-field-appearance-outline > div.mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--outlined {
  background-color: var(--white-smoke);
  border-radius: 4px;
}

.hugeScreen mat-form-field.mat-form-field-appearance-outline > div.mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--outlined {
  background-color: var(--white);
  --mdc-outlined-text-field-focus-outline-color:  var(--mdc-theme-primary, #673ab7) !important;
  --mdc-outlined-text-field-caret-color: var(--mdc-theme-primary, #673ab7) !important;
}

.hugeScreen .mdc-text-field--outlined .mdc-notched-outline__trailing {
  border-bottom: 1px solid #A2A2A2;
  border-radius: 0px !important;
}


label[matformfieldfloatinglabel] {
  // max-width: 100% !important;
  overflow: hidden;
}

input[type=file] {
  height: 50px;
  border-radius: 0px !important;

  outline: 0;

  color: #008BD0;
  background: white;

  border: none;

  font-size: 16px;
}

input[type=file]:disabled {
  color: #E9E8E9;;
}

button.mat-sort-header-button {
  width: auto;
}

button[mat-flat-button] {
  background: #008BD0;
  color: white;
}

button[class~=mat-menu-item] {
  margin: 0px;
  width: 180px !important;
}

button:disabled { 
  opacity: 0.3;
  color: white !important;
}

button:disabled:not(button[mat-button]) button:disabled:not(mat.mdc-button-theme-toggle-button) { 
  opacity: 0.3;
  color: black !important;
}

button:disabled:hover {
  cursor: not-allowed;
}


button:active:not(button[mat-flat-button]):not(button[mat-icon-button]):not(button[mat-menu-item]):not(button.mat-calendar-body-cell) {
  background: #006699;
  outline:0;
}

button:focus {
  outline:0;
}


button.secondary {
  box-sizing: border-box;
  border: 1px solid #008BD0;
  color: #008BD0;
  background: transparent;
}

button.secondary:disabled {
  opacity: 30%;
  border: 0px;
  color: #008BD0;
}

button.secondary:not(button[disabled]):hover {
  border: 2px solid #0076B1;
  color: #0076B1;
}

button.secondary:active {
  border: 2px solid #006699;
  color: #006699;

  outline: 0;
}


button.float {

  background: #FFFFFF;
  opacity: 95%;

  border-radius: 25px;

  box-shadow: 0px 2px 10px rgba(0, 0, 0, .1);

  outline: 0;

  cursor: pointer;
}

button.float:hover {

  background: #FFFFFF;
  opacity: 95%;

  border-radius: 25px;

  box-shadow: 0px 2px 10px rgba(0, 0, 0, .3);

  outline: 0;
}

mat-option {
  height: 50px !important;
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none !important;
}

.support-purplered button[mat-button]{
  background-color: transparent;
}

.error-panel {
  background-color: #FFFFFF;
  border-left: 4px solid #008BD0;
  border-radius: 0px !important;
  color: black;
}

.error-panel vcld-snackbar {
  display: flex;
  align-items: center;
}

.green-success {
  border-left: 4px solid #18A558;
}

.green-success vcld-snackbar > mat-icon.cyan-text {
  color: #18A558;
}

.dmt-disabled {
  opacity: 0.4;
  cursor: default;
}

button[mat-flat-button].mail-button {
  border-radius: 50px !important;
  height: 36px !important;
  padding: 0px 8px;
  font-size: 12px;
}

.filter-drop .mat-mdc-form-field-subscript-wrapper {
  height: 0px;
}